import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import I18n from 'vue-i18n'

// import '@/config/remConfig'

Vue.config.productionTip = false
Vue.use(I18n)

const i18n = new I18n({
  locale: 'zh-CN', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'zh-CN': require('./api/languages/zh-CN.json'),  // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en-US': require('./api/languages/en-US.json')
  }
});

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
